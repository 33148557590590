var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"more-videos"},[(_vm.$route.path == '/videos/more/coCourse')?_c('div',{staticClass:"videos-header"},[_c('v-breadcrumb',{attrs:{"index":_vm.index,"child":_vm.child}})],1):_vm._e(),_c('div',{staticClass:"more-videos-body"},[_c('div',{staticClass:"videos-search-container"},[_c('div',{staticClass:"select-list"},[_c('div',{staticClass:"select-item"},[_c('div',{staticClass:"select-item-title"},[_vm._v(" "+_vm._s(_vm._f("language")("年份"))+" ")]),_c('Select',{staticClass:"my-select",on:{"on-change":_vm.handleChange},model:{value:(_vm.searchForm.year),callback:function ($$v) {_vm.$set(_vm.searchForm, "year", $$v)},expression:"searchForm.year"}},_vm._l((_vm.yearList),function(item){return _c('Option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label)+" ")])}),1)],1)]),_c('div',{staticClass:"search-container"},[_c('Input',{staticClass:"my-select-input",attrs:{"placeholder":_vm._f("language")('搜索'),"search":""},on:{"on-search":_vm.handleChange},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleChange.apply(null, arguments)}},model:{value:(_vm.searchForm.keywords),callback:function ($$v) {_vm.$set(_vm.searchForm, "keywords", $$v)},expression:"searchForm.keywords"}})],1)]),_c('div',{staticClass:"more-videos-filter"}),_c('div',{staticClass:"more-videos-list"},[_c('a-list',{staticClass:"v-card-list",attrs:{"size":"large","pagination":_vm.pagination,"data-source":_vm.listData,"grid":{
                    gutter: 16, xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{staticClass:"v-card-list-item"},[_c('a-list-item-meta',[_c('div',{staticClass:"v-card-list-item-meta box-shadow",attrs:{"slot":"description"},slot:"description"},[_c('div',{staticClass:"v-card-list-avatar point-cursor"},[_c('img',{attrs:{"src":item.cover},on:{"click":function($event){return _vm.gotoDetail(item)}}})]),_c('div',{staticClass:"v-card-list-desc"},[_c('div',{staticClass:"v-card-list-desc-item"},[_c('span',{staticClass:"v-card-list-desc-item-label point-cursor blod-font",on:{"click":function($event){return _vm.gotoDetail(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"v-card-list-desc-item"},[_c('span',{staticClass:"v-card-list-desc-item-label"},[_vm._v(" 视频总数: ")]),_c('span',{staticClass:"v-card-list-desc-item-value"},[_vm._v(" "+_vm._s(item.nums)+" ")])]),_c('div',{staticClass:"videos-detail-info-item v-card-list-desc-item"},[_c('span',{staticClass:"videos-detail-info-item-label v-card-list-desc-item-label"},[_vm._v("创建时间：")]),_c('span',{staticClass:"videos-detail-info-item-value v-card-list-desc-item-value"},[_vm._v(_vm._s(item.writeAt))])]),_c('div',{staticClass:"v-card-list-desc-item"},[_c('span',{staticClass:"v-card-list-desc-item-label"},[_vm._v(" 描述: ")]),_c('span',{staticClass:"v-card-list-desc-item-clamp clamp-5"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])])])],1)}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }