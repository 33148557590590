import { DayUtil } from "@/assets/util/day.js";

export default {
    getList(list) {
        const clsMap = {
            0: "first-ranking",
            1: "two-ranking",
            2: "three-ranking",
        };
        return list.map((item, index) => {
            const {
                cover: { src },
                user,
                teacher,
                title,
                updatedAt,
                viewCount,
                createdAt,
                _id,
                courseType,
                coursePack,
                name,
                remark,
                introduce,
                courseAmount,
                writeAt,
                auth,
            } = item;
            return {
                user: user || teacher, //
                title: title || name, // 标题
                updatedAt: DayUtil.fmtDate(updatedAt),
                days: DayUtil.passedDays(updatedAt), // 发布多少天
                createdAt: DayUtil.fmtDate(createdAt), // 更新时间
                nums: courseAmount || `${viewCount} 次`, // 观看次数或者视频总数
                cls: clsMap[index], // 样式
                desc: remark || introduce, // 描述
                courseType: courseType || "coCourse", // 类型
                coursePack,
                id: _id,
                cover: src, // 封页
                extral: item,
                writeAt: DayUtil.fmtDate(writeAt),
                auth,
            };
        });
    },
};
