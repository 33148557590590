<template>
    <div class="more-videos">
        <div class="videos-header" v-if="$route.path == '/videos/more/videoCourse'">
            <v-breadcrumb :index="index" :child="child"></v-breadcrumb>
        </div>
        <div class="more-videos-body">
            <div class="videos-search-container">
                <div class="select-list">
                    <div class="select-item">
                        <div class="select-item-title">
                            {{ "年份" | language }}
                        </div>
                        <Select class="my-select" v-model="searchForm.year" @on-change="handleChange">
                            <Option v-for="item in yearList" :value="item.value" :key="item.value">
                                {{ item.label }}
                            </Option>
                        </Select>
                    </div>
                </div>
                <div class="search-container">
                    <Input :placeholder="'搜索' | language" search v-model="searchForm.keywords"
                        @keyup.enter.native="handleChange" @on-search="handleChange" class="my-select-input" />
                </div>
            </div>
            <div class="more-videos-filter">
                <!-- <Radio-group v-model="activeBtn" type="button">
                    <Radio label="全部" value="0"></Radio>
                    <Radio label="知识" value="1"></Radio>
                </Radio-group> -->
            </div>
            <div class="more-videos-list">
                <a-list class="v-card-list" size="large" :pagination="pagination" :data-source="listData"
                    :grid="{ gutter: 10, md: 2, lg: 6 }">
                    <a-list-item slot="renderItem" slot-scope="item, index" class="v-card-list-item">
                        <a-list-item-meta>
                            <div class="v-card-list-item-meta box-shadow" slot="description">
                                <div class="v-card-list-cover">
                                    <img alt="cover" class="point-cursor" :src="item.cover" @click="gotoDetail(item)" />
                                </div>
                                <div class="v-card-list-desc">
                                    <div class="v-card-list-desc-item point-cursor" @click="gotoDetail(item)">
                                        <span class="v-card-list-desc-item-clamp">{{ `${item.user}-${item.title}`
                                        }}</span>
                                    </div>
                                    <div class="v-card-list-desc-item">
                                        <span class="v-card-list-desc-item-label">
                                            <Icon type="ios-clock"></Icon> {{ item.writeAt }}
                                        </span>
                                        <span class="v-card-list-desc-item-label v-right">
                                            <Icon type="logo-youtube"></Icon> {{ item.nums }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
            </div>
        </div>
    </div>
</template>

<script>
import { MethodsUtil } from "../../assets/util/method.js";
import VBreadcrumb from "../../compontents/base/VBreadcrumb.vue";
import { httpURL } from "../../assets/util/http";
import { mapState } from 'vuex';
import { DayUtil } from "@/assets/util/day.js";
import qs from "query-string";
import utils from "./utils";
export default {
    name: "MoreVideoCourse",
    components: { VBreadcrumb },
    data() {
        const { type } = this.$route.query;
        const pageSize = 24;
        return {
            index: "",
            child: "",
            type,
            searchForm: {
                keywords: "",
                year: "",
                pageNum: 0,
                packId: "",
                pageSize,
                sort: "-createdAt,-updatedAt,-writeAt",
            },
            yearList: [],
            listData: [],
            tagList: [],
            activeBtn: "0",
            pagination: {
                onChange: (page) => {
                    this.searchForm.pageNum = page;
                    this.$nextTick(() => {
                        this.handleChange();
                    });
                },
                total: 0,
                pageSize,
            },
        };
    },
    mounted() {
        let { index, child } = MethodsUtil.getRouteIndexAndChild(this.$route);
        this.index = index;
        this.child = child;
    },
    computed: {
        ...mapState(["admin"])
    },
    created() {
        this.handleChange();
        this.getYear();
    },
    methods: {
        gotoDetail(rowData) {
            const { courseType = "coCourse", id, coursePack, auth } = rowData;
            if (auth == true && this.admin == null) {
                // this.$router.push('/common/login');
                window.open(`${httpURL}/user/auth`);
                return;
            }
            this.$router.push({
                path: `/videos/${courseType}/${id}`,
                query: { cId: coursePack && coursePack._id },
            });
        },
        handleChange() {
            const { year } = this.searchForm;
            const params = {
                ...this.searchForm,
                year: year == "all" ? "" : year,
            };
            this.fetchNewList(params);
        },
        getYear() {
            let date = new Date();
            let year = date.getFullYear();
            this.searchForm.year = "all";
            this.yearList.push({ label: "全部", value: "all" });
            for (let i = 0; i < 10; i++) {
                this.yearList.push({ label: year - i, value: year - i });
            }
        },
        formatList(list) {
            const result = [];
            for (let i = 0; i < list.length; i++) {
                const {
                    title,
                    viewCount,
                    introduce,
                    videoUrl,
                    courseType,
                    teacher,
                    updatedAt,
                    cover: { src },
                    _id,
                } = list[i];
                result.push({
                    id: _id,
                    cover: src,
                    desc: title,
                    user: teacher,
                    days: DayUtil.passedDays(updatedAt),
                    nums: `${viewCount} 次`,
                    url: videoUrl,
                    extral: list[i],
                });
            }
            return result;
        },
        async fetchNewList(params) {
            // /information/courseSearch?pageSize=10&pageNum=0&year=2022&packId=合集id&keywords=关键词&sort=-writeAt,-updatedAt
            const baseUrl = "/information/courseSearch";
            const newList = qs.stringify({
                ...params,
            });
            // 最新发布
            const newListUrl = `${baseUrl}?${newList}`;
            let resp = await this.$http.get(newListUrl);
            if (resp) {
                const { result, count } = resp;
                this.pagination.total = count;
                this.listData = utils.getList(result); //this.formatList(result);
            }
        },
    },
};
</script>

<style lang="less">
.more-videos {
    .more-videos-body {
        width: 90%;
        margin: 48px auto 0;
    }

    .section-list-item {
        margin-bottom: 32px;
    }

    .more-videos-filter {
        margin-top: 40px;
        margin-bottom: 16px;
        background: #edf2f9;
        text-align: left;
        padding: 8px 16px;
    }
}

.videos-card {
    margin: 0 10px;

    .videos-card-cover {
        overflow: hidden;
        height: 116px;

        &>img {
            display: block;
            width: 100%;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, transform 0.5s;
            transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
            vertical-align: middle;
            border-style: none;
            border-radius: 2px 2px 0 0;
        }

        &:hover>img {
            opacity: 1;
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }

    .videos-card-body {
        padding: 8px;
    }

    .videos-owner {
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        margin-top: 16px;
        height: 22px;
    }

    .videos-desc {
        height: 40px;
        text-align: left;
        margin-top: 4px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 12px;
        font-weight: 500;
        word-break: break-all;
    }

    .videos-actions {
        padding: 4px 0 0;
        text-align: left;
        font-size: 12px;
    }
}

.videos-card:hover {
    box-shadow: 0 0 8px;
}

.videos-search-container {
    position: realtive;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .select-list {
        display: flex;
        align-items: flex-start;

        .select-item {
            display: flex;
            align-items: center;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            .select-item-title {
                margin-right: 10px;
                opacity: 0.6;
                font-size: 14px;
                color: #000;
                letter-spacing: 0;
                flex-shrink: 0;
                line-height: 29px;
            }
        }
    }

    .search-container {
        .search-title {
            margin-bottom: 10px;
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            text-align: left;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .videos-card {
        .videos-card-cover {
            height: 199px;
        }
    }
}
</style>
